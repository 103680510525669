import { useState } from "react"
import Image from "next/image"
import Link from "next/link"
import Block from "@/components/wrappers/block"
import styles from "./current-campaigns.module.css"
import Button from "@/common/button/button"
import TextImageBlock from "@/common/partials/textImageBlock/textImageBlock"
import HeadingLabel from "@/common/partials/headingLabel/headingLabel"
import classNames from "classnames"

export default function CurrentCampaigns({ block }) {
  const { attrs } = block

  if (attrs.cards.length === 0) {
    return null
  }

  const count = attrs.cards.length
  const isSingle = count === 1
  const gap = isSingle ? "none" : "normal"
  const heading = isSingle ? attrs?.singular : attrs?.plural

  return (
    <Block block={block} gap={gap} className={styles.wrapper}>
      {!isSingle && <h2 className="heading">{heading}</h2>}
      <ol className={classNames("campaigns", "cards-" + count)}>
        {attrs.cards.map((item, index) => {
          if (isSingle) {
            return <Single item={item} />
          }
          return <Card item={item} key={`card-${index}`} />
        })}
      </ol>
    </Block>
  )
}

export const Single = ({ item }) => {
  return (
    <li>
      <TextImageBlock layout="left" image={item.image}>
        <div className="flex flex-col items-start">
          {item.tag.label && <HeadingLabel>{item.tag.label}</HeadingLabel>}
          <a className="pb-4" href={item.url}>
            <h2 className="text-has-hover-effect">{item.title}</h2>
          </a>
          <p className="mb-6 text-lg">{item.text}</p>
          <Button
            tagName="a"
            href={item.url}
            theme="lime"
            className="inline-block no-underline">
            {item.link}
          </Button>
        </div>
      </TextImageBlock>
    </li>
  )
}

export const Card = ({ item }) => {
  const [state, setState] = useState("loading")

  return (
    <li className={classNames([styles.card, state])}>
      <Link href={item.url} prefetch={false}>
        <a>
          <div className="image-wrapper">
            <figure>
              {item.image && (
                <Image
                  src={item.image.url}
                  sizes={item.image.sizes}
                  onLoadingComplete={() => setState(false)}
                  alt={item.image.alt}
                  layout="fill"
                  loading="lazy"
                />
              )}
            </figure>
          </div>
        </a>
      </Link>
      <Link href={item.url} prefetch={false}>
        <a>
          <h3>{item.title}</h3>
        </a>
      </Link>
      {item.text && <p>{item.text}</p>}
    </li>
  )
}
